import { render, staticRenderFns } from "./aboutteam.vue?vue&type=template&id=2ddc4158&scoped=true&"
import script from "./aboutteam.vue?vue&type=script&lang=js&"
export * from "./aboutteam.vue?vue&type=script&lang=js&"
import style0 from "./aboutteam.vue?vue&type=style&index=0&id=2ddc4158&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ddc4158",
  null
  
)

export default component.exports